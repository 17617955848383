import React, { createContext, useContext, useState } from "react";

// Create a context object
const ChartStateContext = createContext();

// Create a provider component
export const ChartContextProvider = ({ children }) => {
  const [chartIndicator, setChartIndicator] = useState({
    // error_level: false,
    blockPressure: false,
    volumeDelta: false,
    zigZag: false,
  });

  return (
    <ChartStateContext.Provider value={{ chartIndicator, setChartIndicator }}>
      {children}
    </ChartStateContext.Provider>
  );
};

// Custom hook to use the context easily
export const useChartStateContext = () => {
  return useContext(ChartStateContext);
};
