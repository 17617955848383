import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { RiLockPasswordFill } from "react-icons/ri";
import { FaSearch } from "react-icons/fa";
import { FaPencil } from "react-icons/fa6";

import './AdminTable.css';

const AdminUserTable = () => {
  const [allUser, setAllUser] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [sortOrder, setSortOrder] = useState("asc");
  const [userMonthChangeEmail, setUserMonthChangeEmail] = useState("");
  const [showPopupMonths, setShowPopupMonths] = useState(false);
  const [newSubscriptionMonths, setNewSubscriptionMonths] = useState("");
  const itemsPerPage = 13;
  // const base_url = "http://localhost:3000/api/v1";
  const base_url = "https://mychart-backend.vercel.app/api/v1"

  const handleFetch = async () => {
    const getUserUrl = `${base_url}/admin`;

    try {
      const response = await fetch(getUserUrl, {
        credentials: "include",
      });
      const json = await response.json();

      if (Array.isArray(json)) {
        setAllUser(json);
        setFilteredUsers(json);
      } else {
        console.error("API response is not an array:", json);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetch();
  }, []);

  useEffect(() => {
    const filtered = allUser.filter(user => {
      const userDate = new Date(user.premiumStartDate);
      const matchesSearchTerm = user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (user.utrNo && user.utrNo.includes(searchTerm));
      const matchesDateRange = (!startDate || new Date(startDate) <= userDate) &&
        (!endDate || userDate <= new Date(endDate));

      return matchesSearchTerm && matchesDateRange;
    });

    setFilteredUsers(filtered);
    setCurrentPage(0); // Reset to first page on search
  }, [searchTerm, startDate, endDate, allUser]);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const handleUserUpdate = async (payload) => {
    const userUpdateUrl = `${base_url}/admin/userstatus`;

    try {
      const response = await fetch(userUpdateUrl, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const json = await response.json();

      if (json.statuscode === 200) {
        toast.success(json.message);
      } else {
        toast(json.message);
      }
      handleFetch();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleAcceptRequest = (userId, month) => {
    const startDate = new Date();
    const premiumEndDate = new Date(startDate);
    premiumEndDate.setMonth(premiumEndDate.getMonth() + month);
    handleUserUpdate({
      id: userId,
      status: true,
      premiumEndDate
    });
  };

  const handleDeclineRequest = (userId) => {
    handleUserUpdate({ id: userId, status: false });
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  const handelChangeKey = (userEmail) => {
    setEmail(userEmail);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "newPassword") {
      setError('');
      setNewPassword(value);
    } else if (name === "confirmPassword") {
      setError('');
      setConfirmPassword(value);
    }
  };

  const reset_password = `${base_url}/auth/forgot-password`;

  const handleSubmit = async () => {
    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    } else if (newPassword === '' || confirmPassword === '') {
      toast.error('Please fill all the fields');
      return;
    }

    try {
      const response = await fetch(reset_password, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, newPassword }),
      });

      if (response.ok) {
        toast.success("Password updated successfully");
        handleClosePopup();
        handleFetch();
      } else {
        const data = await response.json();
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error updating password:", error.message);
      toast.error("An error occurred while updating password");
    }
  };
  const handleClearDates = () => {
    setStartDate("");
    setEndDate("");
  };
  const handleSort = () => {
    const order = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(order);
    const sortedUsers = [...filteredUsers].sort((a, b) => {
      const dateA = new Date(a.premiumStartDate);
      const dateB = new Date(b.premiumStartDate);
      return order === "asc" ? dateA - dateB : dateB - dateA;
    });
    setFilteredUsers(sortedUsers);
  };

  const pageCount = Math.ceil(filteredUsers.length / itemsPerPage);
  const displayedUsers = filteredUsers.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

  const handelChangeSubMonths = (userSubChangeMail) => {
    setUserMonthChangeEmail(userSubChangeMail);
    setShowPopupMonths(true);
  };

  const handleInputChangeMonths = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && Number(value) > 0) {
      setNewSubscriptionMonths(value);
    } else {
      toast.error("Please enter a valid number greater than 0");
    }
  };


  const subMonthsUpdateUrl = `${base_url}/admin/change-sub-months`;
  const handleSubmitMonths = async () => {
    if (newSubscriptionMonths === "") {
      toast.error("Please enter the number of months");
      return;
    }

    try {
      const response = await fetch(subMonthsUpdateUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: userMonthChangeEmail, months: newSubscriptionMonths }),
      });

      if (response.ok) {
        toast.success("Subscription months updated successfully");
        handleClosePopupMonths();
        handleFetch();
      } else {
        const data = await response.json();
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error updating subscription months:", error.message);
      toast.error("An error occurred while updating subscription months");
    }
  };

  const handleClosePopupMonths = () => {
    setShowPopupMonths(false);
    setNewSubscriptionMonths("");
  };


  return (
    <>
      {loading ? (<div className="overlay">
        <div className="loader"></div>
      </div>) : (
        
        <div className="flex flex-col mt-5">
          <div className="sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
              
              <div className="grid grid-cols-3 gap-4">
                <div className="form-group flex ">
                  <label htmlFor="startDate px-2 text-white">Start Date:</label>
                  <input
                    type="date"
                    id="startDate"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    className="form-control text-black cursor-pointer mb-4 px-4 py-2 border rounded-md w-full sm:w-1/2 lg:w-2/3"
                  />
                </div>
                
                <div className="form-group flex">
                  <label htmlFor="endDate px-2 text-white">End Date:</label>
                  <input
                    type="date"
                    id="endDate"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    className="form-control text-black cursor-pointer mb-4 px-4 py-2 border rounded-md w-full sm:w-1/2 lg:w-2/3"
                  />
                </div>
                <button type="button" onClick={handleClearDates} className="dark:bg-gray-800 mb-4 text-white rounded-lg lg:w-1/3 ">Clear</button>
              </div>
              <div className="flex flex-column sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center justify-end pb-4 mt-3 mr-2">

                <label for="table-search" className="sr-only">Search</label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 rtl:inset-r-0 rtl:right-0 flex items-center ps-3 pointer-events-none">
                    <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                  </div>
                  <input type="text" id="table-search" value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search by name, email, UTR" />
                </div>
              </div>
              <div className="relative overflow-x-auto shadow-md sm:rounded-lg">

                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr className="bg-gray-700 text-white">
                      <th scope="col" className="px-6 py-6">SL</th>
                      <th scope="col" className="px-6 py-6">NAME</th>
                      <th scope="col" className="px-6 py-6">EMAIL</th>
                      <th scope="col" className="px-6 py-6">PHONE</th>
                      <th scope="col" className="px-6 py-6">PREMIUM USER</th>
                      <th scope="col" className="px-6 py-6">STATUS</th>
                      <th scope="col" className="px-6 py-6 cursor-pointer" onClick={handleSort}>
                        PREMIUM START {sortOrder === "asc" ? "↑" : "↓"}
                      </th>
                      <th scope="col" className="px-6 py-6">No. of Months</th>
                      <th scope="col" className="px-6 py-6 cursor-pointer">
                        PREMIUM END
                      </th>
                      <th scope="col" className="px-6 py-6">UTR NO</th>
                      <th scope="col" className="px-6 py-6">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {displayedUsers.map((user, index) => (
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" key={index}>
                        <td className="px-4 py-4">{currentPage * itemsPerPage + index + 1}</td>
                        <td className="px-4 py-4  font-medium text-gray-900 whitespace-nowrap dark:text-white">{user?.name}</td>
                        <td className="px-4 py-4 flex font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          {user?.email}
                          <span className="pt-1 px-2 cursor-pointer " onClick={() => handelChangeKey(user.email)}>
                            <RiLockPasswordFill />
                          </span>
                        </td>
                        <td className="px-4 py-4 text-white">{user?.phone}</td>
                        <td className="px-4 py-4">
                          <button className={`px-2 text-white font-bold rounded ${user?.isPremium ? 'bg-green-700' : 'bg-lime'}`}>
                            {user?.isPremium ? 'Yes' : 'No'}
                          </button>
                        </td>
                        <td className={`px-4 py-4 ${user.status === 'paid' ? 'text-green-400 font-semibold' : user.status === 'cancel' ? 'text-yellow-400 font-semibold' : ''}`}>
                          {user.status.charAt(0).toUpperCase() + user.status.slice(1)}
                        </td>
                        <td className="px-4 py-4 text-white">{formatDate(user.premiumStartDate)}</td>
                        <td className="px-4 py-4 text-white flex">
                          {user.subscriptionMonths}
                          {user.subscriptionMonths > 0 && (
                            <span className="pt-1 px-2 cursor-pointer" onClick={() => handelChangeSubMonths(user.email)}>
                              <FaPencil />
                            </span>
                          )}
                        </td>
                        <td className="px-4 py-4 text-white">{formatDate(user.premiumEndDate)}</td>
                        <td className="px-4 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{user.utrNo}</td>
                        <td className="px-4 py-4 flex justify-between">
                          {(user.status === "pending" || user.status === "cancel") ? (
                            <>
                              <button
                                className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded"
                                onClick={() => handleAcceptRequest(user._id, user.subscriptionMonths)}
                              >
                                Accept
                              </button>
                              <button
                                className="bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded"
                                onClick={() => handleDeclineRequest(user._id)}
                              >
                                Decline
                              </button>
                            </>
                          ) : (
                            <button
                              className="w-full bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded"
                              onClick={() => handleDeclineRequest(user._id)}
                            >
                              Suspend
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

              </div>
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={"-space-x-px text-base h-10 pagination"}
                activeClassName={"active"}
                pageClassName={"flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"}
                pageLinkClassName={"page-link"}
                previousClassName={"flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"}
                previousLinkClassName={"page-link"}
                nextClassName={"flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"}
                nextLinkClassName={"page-link"}
                breakClassName={"flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"}
                breakLinkClassName={"page-link"}
              />
            </div>
          </div>
        </div>
      )}
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
          <div className=" bg-gray-900  rounded-lg p-8 w-full max-w-md">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-xl font-semibold text-white">Change Password</h2>
              <button className="text-gray-400 hover:text-gray-800 focus:outline-none" onClick={handleClosePopup}>
                <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
            <h6 className="mb-4  font-bold" style={{ color: "green" }}>{email}</h6>
            <div className="mb-4">
              <label htmlFor="newPassword" className="block mb-2 text-white">New Password</label>
              <input
                type="password"
                id="newPassword"
                name="newPassword"
                value={newPassword}
                onChange={handleInputChange}
                className="border rounded-md px-3 py-2 w-full text-black"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="confirmPassword" className="block mb-2 text-white">Confirm Password</label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={confirmPassword}
                onChange={handleInputChange}
                className="border rounded-md px-3 py-2 w-full text-black"
              />
            </div>
            {error && <div className="text-red-500 mb-4">{error}</div>}
            <div className="flex justify-end">
              <button className="bg-blue-500 text-white px-4 py-2 rounded-md mr-2" onClick={handleSubmit}>
                Submit
              </button>
              <button className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md" onClick={handleClosePopup}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {showPopupMonths && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
          <div className="bg-gray-900 rounded-lg p-8 w-full max-w-md">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-xl font-semibold text-white">Change Subscription Months</h2>
              <button className="text-gray-400 hover:text-gray-800 focus:outline-none" onClick={handleClosePopupMonths}>
                <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
            <h6 className="mb-4 font-bold " style={{ color: "green" }}>{userMonthChangeEmail}</h6>
            <div className="mb-4">
              <label htmlFor="newSubscriptionMonths" className="block text-white mb-2">New Subscription Months</label>
              <input
                type="text"
                id="newSubscriptionMonths"
                name="newSubscriptionMonths"
                onChange={handleInputChangeMonths}
                className="border rounded-md px-3 py-2 w-full text-black"
              />
            </div>
            <div className="flex justify-end">
              <button className="bg-blue-500 text-white px-4 py-2 rounded-md mr-2" onClick={handleSubmitMonths}>
                Submit
              </button>
              <button className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md" onClick={handleClosePopupMonths}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}


    </>
  );
};

export default AdminUserTable;
