import React from 'react';
import TradingViewWidget from '../../components/Chart/TradingViewWidget';

const USDJPY = () => {
  return (
    <div className="h-screen w-full">
    <TradingViewWidget symbol={`USDJPY`} />
  </div>
  );
};

export default USDJPY;