import React, { useState } from "react";
import { GrClose } from "react-icons/gr";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const SignUpModal = ({ isOpen, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
  });

  if (!isOpen) return null;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setError("");

    if (name === "phone") {
      // Allow only numbers and a maximum of 10 digits
      const numericValue = value.replace(/\D/g, "").slice(0, 10);
      setData({
        ...data,
        [name]: numericValue,
      });
    } else {
      setData({
        ...data,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!data.name || !data.email || !data.phone || !data.password || !data.confirmPassword) {
      setError("Please fill in all fields");
      setIsLoading(false);
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(data.email)) {
      setError("Invalid Email Address");
      setIsLoading(false);
      return;
    }

    if (data.phone.length < 10) {
      setError("Invalid Phone Number");
      setIsLoading(false);
      return;
    }

    if (data.password !== data.confirmPassword) {
      setError("Passwords don't match");
      setIsLoading(false);
    } else {
      setData({
        ...data,
        password: data.password,
      });

      try {
        const baseURL = "https://mychart-backend.vercel.app/api/v1";
        const signupURL = `${baseURL}/auth/sign-up`;
        const response = await fetch(signupURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          credentials: "include",
          body: JSON.stringify(data),
        });
        const json = await response.json();
        toast.dismiss();

        if (json.success === false) {
          toast.error(json.message);
          setIsLoading(false);
        } else {
          if (json.success === true) {
            toast.success(json.message);
            onClose();
            toast("Please log in first", { position: "bottom-right" });
            setIsLoading(false);
          } else {
            toast.error(json.errorMessage);
            setIsLoading(false);
          }
        }
      } catch (error) {
        toast.error(error.message);
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      {isLoading && (
        <div className="overlay">
          <div className="loader"></div>
        </div>
      )}
      <div className="fixed z-10 inset-0 overflow-y-auto py-2">
        <div className="flex items-center justify-center min-h-screen px-4">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="flex justify-center shadow-xl transform transition-all sm:max-w-lg sm:w-full">
            <div className="w-[300px] sm:w-[500px] bg-gray-900 p-10 rounded-lg border border-gray-700">
              <div className="flex justify-end">
                <GrClose
                  onClick={onClose}
                  className="w-6 h-6 cursor-pointer text-gray-100 border rounded-full p-1"
                ></GrClose>
              </div>

              <h2 className="text-2xl font-semibold mb-4 text-gray-400">
                GO CHART SIGN UP
              </h2>

              <div className="flex-col sm:flex gap-4 mb-2">
                <div>
                  <label
                    htmlFor="username"
                    className="block text-gray-400 font-medium mb-1"
                  >
                    Full Name
                  </label>
                  <input
                    onChange={handleOnChange}
                    name="name"
                    type="text"
                    id="firstName"
                    className="bg-transparent text-gray-400 border-gray-600 placeholder-gray-700 input"
                    placeholder="Enter your full name"
                  />
                </div>

                <div>
                  <label
                    htmlFor="email"
                    className="block text-gray-400 font-medium mb-1"
                  >
                    Email
                  </label>
                  <input
                    onChange={handleOnChange}
                    name="email"
                    type="email"
                    id="email"
                    className="bg-transparent text-gray-400 border-gray-600 placeholder-gray-700 input"
                    placeholder="Enter your email"
                  />
                </div>
                <div>
                  <label
                    htmlFor="phone"
                    className="block text-gray-400 font-medium mb-1"
                  >
                    Phone
                  </label>
                  <input
                    onChange={handleOnChange}
                    name="phone"
                    type="text"
                    id="phone"
                    value={data.phone}
                    className="bg-transparent text-gray-400 border-gray-600 placeholder-gray-700 input"
                    placeholder="Enter your phone number"
                  />
                </div>
              </div>

              <div className="mb-4">
                <label
                  htmlFor="password"
                  className="block text-gray-400 font-medium mb-1"
                >
                  Password
                </label>
                <input
                  onChange={handleOnChange}
                  name="password"
                  type="password"
                  id="password"
                  className="bg-transparent text-gray-400 border-gray-600 placeholder-gray-700 input"
                  placeholder="Enter your password"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="confirmPassword"
                  className="block text-gray-400 font-medium mb-1"
                >
                  Confirm Password
                </label>
                <input
                  onChange={handleOnChange}
                  name="confirmPassword"
                  type="password"
                  id="confirmPassword"
                  className="bg-transparent input text-gray-400 placeholder-gray-700 border-gray-600"
                  placeholder="Confirm your password"
                />
                {error && <p className="text-red-400">{error}</p>}
              </div>

              <button
                onClick={handleSubmit}
                className="btn w-full bg-gray-800 text-gray-400"
              >
                Sign up
              </button>

              <div className="pt-4 text-gray-400">
                <div>
                  <div className="relative border border-gray-600 my-4">
                    <p className="absolute -top-4 left-[35%] bg-[#111827]">
                      Have an account?
                    </p>
                  </div>

                  <Link
                    onClick={onClose}
                    to="/"
                    className="flex items-center justify-center p-2 bg-cyan-800 rounded hover:opacity-25 hover:transition-opacity hover:delay-150 font-semibold"
                  >
                    <button>SIGN IN</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpModal;
