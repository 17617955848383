import React from "react";
import forbidden from "../../assets/403 Error Forbidden-pana.png";
import { Link } from "react-router-dom";
export default function Forbidden() {
  return (
    <div className="w-full h-screen">
      <div className="flex flex-col justify-center items-center pb-4">
        <img className="w-auto h-[500px]" src={forbidden} alt="" />

        <Link to="/">
          <button className="text-white  rounded-md hover:opacity-80  bg-blue-600 p-4">
            Go to Homepage{" "}
          </button>
        </Link>
      </div>
    </div>
  );
}
