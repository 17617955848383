import { Outlet } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer.jsx/Footer";

export default function Main(children) {
  return (
    <div>
      <Header></Header>

      <Outlet>{children}</Outlet>
      <Footer />
    </div>
  );
}
