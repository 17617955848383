import React from 'react'
import TradingViewWidget from '../../components/Chart/TradingViewWidget'

export default function EURJPY() {
  return (
    <div className="h-screen w-full">
    <TradingViewWidget symbol={`EURJPY`} />
  </div>
  )
}
