import React from "react";
import { useUserContext } from "../../Context/userContext";
import LoginPage from "../../Pages/LoginPage/LoginPage";
import Main from "../layout/main";

export default function PrivateRoute() {
  const { user } = useUserContext();

  return <>{user?.email ? <Main></Main> : <LoginPage></LoginPage>}</>;
}
