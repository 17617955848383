import React from "react";

import AdminSidebar from "../../Sidebar/AdminSidebar";
import { Outlet } from "react-router-dom";

export default function AdminLayout({ children }) {
  return (
    <AdminSidebar>
      <Outlet>{children}</Outlet>
    </AdminSidebar>
  );
}
