// import React, { useEffect, useState } from "react";
// import { toast } from "react-toastify";

// export default function AdminUpdatePayment() {
//   const [formData, setFormData] = useState({});
//   const [currentData, setCurrentData] = useState({});

//   // const handleOnChange = (input) => {
//   //   setFormData({
//   //     id: currentData._id,
//   //     payment: { [input.target.name]: input.target.value },
//   //   });
//   // };

//   const handleOnChange = (input) => {
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       id: currentData._id,
//       payment: {
//         ...prevFormData.payment,
//         [input.target.name]: input.target.value,
//       },
//     }));
//   };

//   const handleGetPayentInfo = async () => {
//     try {
//       // const base_url = process.env.REACT_APP_BASE_URL;
//     const base_url = "https://mychart-backend.vercel.app/api/v1";
//       const update_url = `${base_url}/admin/paymentinfo`;
//       const response = await fetch(update_url, {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//           "Access-Control-Allow-Origin": "*",
//         },

//         credentials: "include",
//       });
//       const json = await response.json();

//       setCurrentData(json.data || {});
//     } catch (error) {
//       toast.error(error.message);
//     }
//   };

//   const handleUpdate = async (e) => {
//     e.preventDefault();

//     toast.info("please wait...");

//     try {
//       // const base_url = process.env.REACT_APP_BASE_URL;
//     const base_url = "https://mychart-backend.vercel.app/api/v1";
//       const update_url = `${base_url}/admin/update-payment`;
//       const response = await fetch(update_url, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           "Access-Control-Allow-Origin": "*",
//         },

//         credentials: "include",
//         body: JSON.stringify(formData),
//       });
//       const json = await response.json();

//       toast.dismiss();

//       if (json.error) {
//         toast.error(json.error);
//       } else {
//         json.statusCode === 200
//           ? toast.success(json.message)
//           : toast.error(json.message);
//       }
//     } catch (error) {
//       toast.error(error.message);
//     }
//     handleGetPayentInfo();
//   };

//   useEffect(() => {
//     handleGetPayentInfo();
//   }, [formData]);

//   return (
//     <div className="w-full h-screen   ">
//       <div className="w-full h-auto flex flex-col justify-center items-center gap-4 ">
//         <div className=" text-4xl  rounded border-gray-800 p-4      gap-4">
//           <div className=" flex justify-center items-center">
//             <img
//               className="w-[100px] h-[100px]"
//               src={currentData.qrcodeUrl}
//               alt=""
//             />
//           </div>
//           <h1 className="text-green-600 font-semibold text-2xl sm:text-4xl">
//             CURRENT PLANS{" "}
//           </h1>
//           <h1 className="font text-2xl sm:text-4xl text-blue-500">
//             {currentData.month} MONTHS{" "}
//             <span className="font-bold"> ₹{currentData.price}</span> INR{" "}
//           </h1>
//         </div>

//         <div className="  rounded border-gray-800 p-4 flex  flex-col   gap-2">
//           <div className="flex flex-col ">
//             <label htmlFor="">New Months</label>
//             <input
//               onChange={handleOnChange}
//               name="month"
//               type="number"
//               required
//               id="month"
//               className="bg-transparent input text-gray-400 placeholder-gray-700 border-gray-600"
//               // placeholder="1234"
//               placeholder="e:g ; 2 "
//             />
//           </div>
//           <div className="flex flex-col ">
//             <label htmlFor="">Enter new price</label>
//             <input
//               onChange={handleOnChange}
//               name="price"
//               type="text"
//               required
//               id="transations"
//               className="bg-transparent input text-gray-400 placeholder-gray-700 border-gray-600"
//               // placeholder="1234"
//               placeholder="Enter your price"
//             />
//           </div>
//           <div className="flex flex-col ">
//             <label htmlFor="">Image url</label>
//             <input
//               onChange={handleOnChange}
//               name="qrcodeUrl"
//               type="url"
//               required
//               id="qrcodeUrl"
//               className="bg-transparent input text-gray-400 placeholder-gray-700 border-gray-600"
//               // placeholder="1234"
//               placeholder="http://example.com"
//             />
//           </div>

//           <button
//             onClick={handleUpdate}
//             className="bg-green-600 p-1 border  rounded">
//             UPDATE
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// }



import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

export default function AdminUpdatePayment() {
  const [formData, setFormData] = useState({});
  const [currentData, setCurrentData] = useState({});

  // Function to convert Google Drive file URL to thumbnail URL
  const convertToThumbnailUrl = (fileUrl) => {
    const fileId = extractFileId(fileUrl);
    if (fileId) {
      return `https://drive.google.com/thumbnail?id=${fileId}&sz=w1000`;
    } else {
      // Handle invalid or unsupported URL format
      return null;
    }
  };

  // Function to extract file ID from Google Drive file URL
  const extractFileId = (url) => {
    const match = url.match(/\/file\/d\/(.+?)\/view/);
    if (match && match[1]) {
      return match[1];
    } else {
      // Handle invalid or unsupported URL format
      return null;
    }
  };

  const handleOnChange = (input) => {
    if (input.target.name === "qrcodeUrl") {
      const thumbnailUrl = convertToThumbnailUrl(input.target.value);
      setFormData((prevFormData) => ({
        ...prevFormData,
        id: currentData._id,
        payment: {
          ...prevFormData.payment,
          qrcodeUrl:thumbnailUrl,
        },
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        id: currentData._id,
        payment: {
          ...prevFormData.payment,
          [input.target.name]: input.target.value,
        },
      }));
    }
  };
    // const base_url = "http://localhost:3000/api/v1";

  const handleGetPayentInfo = async () => {
    try {
      const base_url = "https://mychart-backend.vercel.app/api/v1";
      const update_url = `${base_url}/admin/paymentinfo`;
      const response = await fetch(update_url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        credentials: "include",
      });
      const json = await response.json();
      setCurrentData(json.data || {});
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    toast.info("please wait...");

    try {
      const base_url = "https://mychart-backend.vercel.app/api/v1";
      const update_url = `${base_url}/admin/update-payment`;
      const response = await fetch(update_url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        credentials: "include",
        body: JSON.stringify(formData),
      });
      const json = await response.json();

      toast.dismiss();

      if (json.error) {
        toast.error(json.error);
      } else {
        json.statusCode === 200
          ? toast.success(json.message)
          : toast.error(json.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
    handleGetPayentInfo();
  };

  useEffect(() => {
    handleGetPayentInfo();
  }, [formData]);

  return (
    <div className="w-full h-screen   ">
      <div className="w-full h-auto flex flex-col justify-center items-center gap-4 ">
        <div className=" text-4xl  rounded border-gray-800 p-4      gap-4">
          <div className=" flex justify-center items-center">
            <img
              className="w-[100px] h-[100px]"
              src={currentData.qrcodeUrl}
              alt=""
            />
          </div>
          <h1 className="text-green-600 font-semibold text-2xl sm:text-4xl">
            CURRENT PLANS{" "}
          </h1>
          <h1 className="font text-2xl sm:text-4xl text-blue-500">
            {currentData.month} MONTHS{" "}
            <span className="font-bold"> ₹{currentData.price}</span> INR{" "}
          </h1>
        </div>

        <div className="  rounded border-gray-800 p-4 flex  flex-col   gap-2">
          <div className="flex flex-col ">
            <label htmlFor="">New Months</label>
            <input
              onChange={handleOnChange}
              name="month"
              type="number"
              required
              id="month"
              className="bg-transparent input text-gray-400 placeholder-gray-700 border-gray-600"
              placeholder="e:g ; 2 "
            />
          </div>
          <div className="flex flex-col ">
            <label htmlFor="">Enter new price</label>
            <input
              onChange={handleOnChange}
              name="price"
              type="text"
              required
              id="transations"
              className="bg-transparent input text-gray-400 placeholder-gray-700 border-gray-600"
              placeholder="Enter your price"
            />
          </div>
          <div className="flex flex-col ">
            <label htmlFor="">Image url</label>
            <input
              onChange={handleOnChange}
              name="qrcodeUrl"
              type="url"
              required
              id="qrcodeUrl"
              className="bg-transparent input text-gray-400 placeholder-gray-700 border-gray-600"
              placeholder="http://example.com"
            />
          </div>

          <button
            onClick={handleUpdate}
            className="bg-green-600 p-1 border  rounded"
          >
            UPDATE
          </button>
        </div>
      </div>
    </div>
  );
}

