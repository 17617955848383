import React from "react";
// import InstitutionalOrderBlockPressure from "../components/Menubar/TestInstitional";
// import TestChart from "../components/Chart/TestChart";
import TradingViewWidget from "../components/Chart/TradingViewWidget";


export default function Home() {
  return (
    // <div className=' h-screen flex items-center justify-center text-4xl'>graph here</div>

    <div className="h-screen w-full">
      <TradingViewWidget />
    </div>
  
  );
}
