import React from 'react';

const FullDate = () => {
  // Get current date
  const currentDate = new Date();
  
  // Define months array to map month index to month name
  const months = [
    "January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"
  ];

  // Format the date
  const formattedDate = `${months[currentDate.getMonth()]} ${currentDate.getDate()}, ${currentDate.getFullYear()}`;

  return (
    <div className="text-xs sm:text-lg font-semibold text-gray-400">
      Date : {formattedDate}
    </div>
  );
};

export default FullDate;
