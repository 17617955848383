import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FiLogOut, FiUser, FiChevronLeft, FiMenu } from "react-icons/fi";
import { toast } from "react-toastify";
import { useUserContext } from "../../Context/userContext";
import { GrDocumentUpdate } from "react-icons/gr";
import { RiLockPasswordFill } from "react-icons/ri";
import { MdAddModerator } from "react-icons/md";
import logo from "../../icon.jpg";
import './adminSidebar.css'

const AdminSidebar = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false); 
  const { setUser } = useUserContext();

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleSignOut = async () => {
    // const base_url = "http://localhost:3000/api/v1";
    const base_url = "https://mychart-backend.vercel.app/api/v1";
    const logout_url = `${base_url}/auth/sign-out`;
    try {
      const response = await fetch(logout_url, {
        credentials: "include",
      });
      const json = await response.json();
      console.log(response.ok)
      if (response.ok) {
        setUser({});
        toast.success(json.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const location = useLocation();
  const isActive = (path) => location.pathname === path;


  return (
    <div className="flex h-screen" style={{ backgroundColor: "#121826" }}>
      {/* Sidebar */}
      
      {/* Collapsible button */}
      <button
        className="md:hidden fixed float-right mt-2 right-4 bg-gray-800 text-white p-2 rounded-full shadow-lg z-50 "
        onClick={toggleSidebar}>
        {isCollapsed ? <FiMenu /> : <FiChevronLeft />}
      </button>
      <div
        className={`sm:left-0 sm:top-0 h-auto sm:w-64 bg-gray-800 text-white p-4 transition-all duration-300 sidebar ${
          isCollapsed ? "hidden sm:block translate-x-full" : "translate-x-0"
        }`}>
        {/* Logo */}
        <Link to="/"> 
        <div className="flex items-center mb-4">
          <img src={logo} alt="Logo" className="h-24 w-auto rounded-full mr-2 "/>
        </div>
        </Link>
        {/* Navigation */}
        <ul>
          <li className={`py-4 sidebarLink ${isActive("/admin") ? "active" : ""}`}>
            <Link to="/admin" className="flex items-center">
              <span className="mr-2">
                <FiUser />
              </span>
              <span
                className={`transition-all duration-300 text-xs sm:text-base ${
                  isCollapsed ? "opacity-0" : "opacity-100"
                }`}>
                All Users
              </span>
            </Link>
          </li>
          <li className={`py-4 sidebarLink ${isActive("/admin/user-req") ? "active" : ""}`}>
            <Link to="/admin/user-req" className="flex items-center">
              <span className="mr-2">
                <MdAddModerator />
              </span>
              <span
                className={`transition-all duration-300 text-xs sm:text-base ${
                  isCollapsed ? "opacity-0" : "opacity-100"
                }`}>
                Requested Users
              </span>
            </Link>
          </li>
          <li className={`py-4 sidebarLink ${isActive("/admin/update-payment") ? "active" : ""}`}>
            <Link to="/admin/update-payment" className="flex items-center">
              <span className="mr-2">
                <GrDocumentUpdate />
              </span>
              <span
                className={`transition-all duration-300 text-xs sm:text-base ${
                  isCollapsed ? "opacity-0" : "opacity-100"
                }`}>
                Update Payment
              </span>
            </Link>
          </li>

          <li className={`py-4 sidebarLink ${isActive("/admin/reset-password") ? "active" : ""}`}>
            <Link to="/admin/reset-password" className="flex items-center">
              <span className="mr-2">
                <RiLockPasswordFill />
              </span>
              <span
                className={`transition-all duration-300 text-xs sm:text-base ${
                  isCollapsed ? "opacity-0" : "opacity-100"
                }`}>
                Reset Password
              </span>
            </Link>
          </li>
          {/* Add more navigation items as needed */}
        </ul>
        {/* Logout */}
        <button
          className={`mt-auto flex items-center ${
            isCollapsed ? "justify-center" : ""
          }`}
          title="sign out"
          onClick={handleSignOut}>
          <span
            className={`ml-2 transition-all duration-300 text-xs sm:text-base ${
              isCollapsed ? "opacity-0" : "opacity-100"
            }`}>
        
          </span>
        </button>
      </div>


      {/* Content */}
      <div className=" p-2 w-full overflow-x-auto ">{children}</div>
    </div>
  );
};

export default AdminSidebar;
