import React from "react";
import TradingViewWidget from "../../components/Chart/TradingViewWidget";

export default function GBPUSD() {
  return (
    <div className="h-screen w-full">
      <TradingViewWidget symbol={`GBPUSD`} />
    </div>
  );
}
