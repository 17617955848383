import React from 'react'
import TradingViewWidget from '../../components/Chart/TradingViewWidget'

export default function CADJPY() {
  return (
    <div className="h-screen w-full">
    <TradingViewWidget symbol={`CADJPY`} />
  </div>
  )
}
