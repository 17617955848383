import React from "react";
import TradingViewWidget from "../../components/Chart/TradingViewWidget";

export default function AUDUSD() {
  return (
    <div className="h-screen w-full">
      <TradingViewWidget symbol={`AUDUSD`} />
    </div>
  );
}
