import React, { useState } from 'react';
import { toast } from "react-toastify";
import { useUserContext } from '../../Context/userContext'; // Assuming you have a UserContext

const AdminResetPassword = () => {
  const { user } = useUserContext();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');



  const handelOnchange = (e) => {
    if (e.target.name === 'newPassword') {
      setError('');
      setNewPassword(e.target.value);
    } else if (e.target.name === 'confirmPassword') {
      setError('');
      setConfirmPassword(e.target.value);
    }
  }
  
  const base_url = 'https://mychart-backend.vercel.app/api/v1'
  // const reset_password = "http://localhost:3000/api/v1/auth/sign-in";
   const reset_password = `${base_url}/auth/forgot-password`;

  // const handleResetPassword = () => {

  //   if (newPassword !== confirmPassword) {
  //     setError('Passwords do not match');
  //     return;
  //   }

  //   setNewPassword('');
  //   setConfirmPassword('');
  //   setError('');
  // };


  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
          setError('Passwords do not match');
          return;
        }else if(newPassword === '' || confirmPassword === ''){
          setError('Please fill all the fields');
        }

        const formData= {
          email: user.email,
          newPassword: newPassword,
        }
    toast.info("please wait...");

    try {
  
      const response = await fetch(reset_password, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          //  "Access-Control-Allow-Origin": "*",
        },
        credentials: "include",
        body: JSON.stringify(formData),
      });
      const json = await response.json();
      // console.log('Server response:', json); 
      if (json.success) {
        // Password reset successful
        toast.success(json.message);
        // Clear form fields and error state
        setNewPassword('');
        setConfirmPassword('');
        setError('');
      } else {
        // Password reset failed
        setError(json.message);
      } 

    } catch (error) {
      toast.error(error.message);
      console.error('Error setting user:', error);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h2 className="text-2xl font-bold mb-4">Admin Reset Password</h2>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="newPassword">
            Email:
          </label>
          <h6 className="text-1xl font-bold mb-4" style={{ color: 'green' }}>{user.email}</h6>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="newPassword">
            New Password:
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="newPassword"
            type="text"
            name='newPassword'
            value={newPassword}
            onChange={handelOnchange}
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confirmPassword">
            Confirm Password:
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="confirmPassword"
            type="text"
            name='confirmPassword'
            value={confirmPassword}
            onChange={handelOnchange}
          />
        </div>
        {error && <div className="text-red-500 mb-4">{error}</div>}
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          onClick={handleResetPassword}
        >
          Reset Password
        </button>
      </div>
    </div>
  );
};

export default AdminResetPassword;
