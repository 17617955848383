import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useUserContext } from "../../Context/userContext";

// export default function Navmenu() {
//   const [activeMenuItem, setActiveMenuItem] = useState(null);
//   const { user } = useUserContext();
//   const [disabledMenuItem, setDisabledMenuItem] = useState(true);
//   const [tooltip, setTooltip] = useState("Premium user required");
//   console.log("user" + user.isPremium)

//   useEffect(() => {
//     // Update disabledMenuItem and tooltip based on user's premium status
//     if (user.isPremium) {
//       setDisabledMenuItem(false);
//       setTooltip("");
//     } else {
//       setDisabledMenuItem(true);
//       setTooltip("Premium user required");
//     }
//   }, [user]);

//   // Function to handle clicking on a menu item
//   const handleMenuItemClick = (menuItem) => {
//     setActiveMenuItem(menuItem);
//   };

//   const menuItems = [
//     { id: 1, label: "EUR/USD", link: "/eur-usd" },
//     { id: 2, label: "GBP/USD", link: "/gbp-usd" },
//     { id: 3, label: "EUR/GBP", link: "/eur-gbp" },
//     { id: 4, label: "USD/CAD", link: "/usd-cad" },
//     { id: 5, label: "USD/JPY", link: "/usd-jpy" },
//     { id: 6, label: "CAD/JPY", link: "/cad-jpy" },
//     { id: 7, label: "AUD/USD", link: "/aud-usd" },
//     { id: 8, label: "GBP/JPY", link: "/gbp-jpy" },
//     { id: 9, label: "EUR/JPY", link: "/eur-jpy" },
//   ];

//   return (
//     <div className="flex justify-center py-2">
//       <div className="flex overflow-x-auto scroll-smooth scrollbar-track-gray-200">
//         {menuItems.map((menuItem) => (
//           <button
//             key={menuItem.id}
//             className={`py-2 px-4 rounded-lg text-gray-500  ${
//               !disabledMenuItem && activeMenuItem === menuItem.id
//                 ? "bg-gray-200 text-gray-800 font-semibold"
//                 : ""
//             }`}
//             onClick={() => handleMenuItemClick(menuItem.id)}>
//             <Link
//               to={menuItem.link}
//               title={disabledMenuItem ? tooltip : ""}
//               className={
//                 disabledMenuItem ? "cursor-not-allowed" : "hover:bg-gray-200"
//               }
//               onClick={(e) => disabledMenuItem && e.preventDefault()}>
//               {menuItem.label}
//             </Link>
//           </button>
//         ))}
//       </div>
//     </div>
//   );
// }


export default function Navmenu() {
  const [activeMenuItem, setActiveMenuItem] = useState(1); // Set initial active menu item to 1

  const { user } = useUserContext();
  const [disabledMenuItem, setDisabledMenuItem] = useState(!user.isPremium);
  const [tooltip, setTooltip] = useState("Premium user required");

  useEffect(() => {
    if (user.isPremium) {
      setDisabledMenuItem(false);
      setTooltip("");
    } else {
      setDisabledMenuItem(true);
      setTooltip("Premium user required");
    }
  }, [user]);

  // Function to handle clicking on a menu item
  const handleMenuItemClick = (menuItem) => {
    setActiveMenuItem(menuItem);
  };

  const menuItems = [
    { id: 1, label: "EUR/USD", link: "/eur-usd" },
    { id: 2, label: "GBP/USD", link: "/gbp-usd" },
    { id: 3, label: "EUR/GBP", link: "/eur-gbp" },
    { id: 4, label: "USD/CAD", link: "/usd-cad" },
    { id: 5, label: "USD/JPY", link: "/usd-jpy" },
    { id: 6, label: "CAD/JPY", link: "/cad-jpy" },
    { id: 7, label: "AUD/USD", link: "/aud-usd" },
    { id: 8, label: "GBP/JPY", link: "/gbp-jpy" },
    { id: 9, label: "EUR/JPY", link: "/eur-jpy" },
  ];

  return (
    <div className="flex justify-center py-2">
      <div className="flex overflow-x-auto scroll-smooth scrollbar-track-gray-200">
        {menuItems.map((menuItem) => (
          <button
            key={menuItem.id}
            className={`py-2 px-4 rounded-lg text-gray-500  ${
              !disabledMenuItem && activeMenuItem === menuItem.id
                ? "bg-gray-200 text-gray-800 font-semibold"
                : ""
            }`}
            onClick={() => handleMenuItemClick(menuItem.id)}>
            <Link
              to={menuItem.link}
              title={disabledMenuItem ? tooltip : ""}
              className={
                disabledMenuItem ? "cursor-not-allowed" : "hover:bg-gray-200"
              }
              onClick={(e) => disabledMenuItem && e.preventDefault()}>
              {menuItem.label}
            </Link>
          </button>
        ))}
      </div>
    </div>
  );
}
