import React, { useState } from "react";
import { json, Link } from "react-router-dom";
import SignUpModal from "../../components/SignUp/SignUpModal";
import "./LoginPage.css";
import Footer from "../../components/Footer.jsx/Footer";
import { toast } from "react-toastify";
import { useUserContext } from "../../Context/userContext";
import logo from '../../icon.jpg';
import bannerImg from '../../assets/banner-image-1.png';
import bannerImg2 from '../../assets/banner-image-2.png';
import { FaStar, FaStarHalfAlt } from "react-icons/fa";
import { MdGroups } from "react-icons/md";

const LoginPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const { setUser } = useUserContext();
  const [isNavOpen, setIsNavOpen] = useState(false);

  const base_url = 'https://mychart-backend.vercel.app/api/v1'
  // const base_url = "http://localhost:3000/api/v1/auth/sign-in";

  
   const login_url = `${base_url}/auth/sign-in`;


  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleOnChange = (input) => {
    setFormData({ ...formData, [input.target.name]: input.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // toast.info("Please wait...");
    setIsLoading(true);

    try {
      const response = await fetch(login_url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(formData),
      });
      const json = await response.json();
      toast.dismiss();

      if (json.success === false) {
        toast.error(json.message);
        setIsLoading(false);
      } else {
        setUser(json.data.user); // Store user data in context/state
        localStorage.setItem("sessionToken", json.data.sessionToken); // Store session token in localStorage
        toast.success(json.message);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Login failed. Please try again.");
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && (
        <div className="overlay">
          <div className="loader"></div>
        </div>
      )}

      <nav className="bg-white navbar">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <a href="#" className="flex items-center space-x- rtl:space-x-reverse">
            <img src={logo} className="h-12 rounded-xl" alt="GoChart Logo" />
          </a>
          <button 
            data-collapse-toggle="navbar-default" 
            type="button" 
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" 
            aria-controls="navbar-default" 
            aria-expanded={isNavOpen} 
            onClick={toggleNav}
          >
            <span className="sr-only">Open main menu</span>
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
            </svg>
          </button>
          <div className={`${isNavOpen ? 'block' : 'hidden'} w-full md:block md:w-auto`} id="navbar-default">
            <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-white-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-white-800 md:dark:bg-white-900 dark:border-white-700">
              <li className="nav-item">
                <Link to="https://telegram.me/gocharts" className="py-2 px-3 nav-link">Contact</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <section className="main px-4 md:px-8">
        <div className="container mx-auto lg:p-6 ">
          <div className="flex flex-wrap mx-4">
            <div className="w-full md:w-2/3 px-4">
              <div className="text-container px-3">
                <p className="lg:text-2xl md:text-2xl sm:text-lg heading text-black font-bold mb-4">Gochart.in One Of The Best Trading And Technical Analysis Platform</p>
                <p className="content lg:text-base md:text-base text-xs text-black lg:mb-8 md:mb-6 sm:mb-2">Experience The Power of Gochart.in, a Decision Support Tool That Provides high-Quality Charts, Reliable Market Data, and Advanced Analytical Tools. All the information is Sourced directly From The Exchanges, Making it a  Reliable and trustworthy Resource.</p>
              </div>
             
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4 border-black form-container">
                <div className="">
                  <form className="space-y-4 md:space-y-6 p-6  md:py-8 md:px-12" action="#">
                    <div className="mb-3">
                      <label htmlFor="email" className="block mb-2 text-2xl dark:text-black">Email</label>
                      <input type="email" name="email" id="email" onChange={handleOnChange} className="form-input bg-white border rounded-lg block w-full p-2" placeholder="Enter Email" required=""/>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="password" className="block mb-2 text-2xl dark:text-black">Password</label>
                      <input type="password" name="password" id="password" onChange={handleOnChange} className="form-input bg-white border rounded-lg block w-full p-2" placeholder="Enter Password" required=""/>
                    </div>
                    <div className="button-group text-center">
                      <button onClick={handleSubmit} className="btn text-base w-2/4 bg-black text-white"> Sign In</button>
                      <p className="text-black text-xl py-4 font-semibold">Don't have an account?</p>
                      <button className="btn text-base w-2/4 bg-black text-white" onClick={openModal}> Sign Up</button>
                    </div>
                  </form>
                </div>
                <div className="text-center lg:py-8 md:py-8">
                  <img className="" src={bannerImg2} alt="image-banner"/>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/3 px-4">
              <div className="flex justify-center w-full ">
                <img className="banner-img h-96" src={bannerImg} alt="image-banner" />
              </div>
              <div className="text-center">
                <p className="text-2xl text-black font-semibold mb-4">Loved By Lakh Of Users</p>
                <div className="flex justify-center gap-3">
                  <div className="bg-black text-white p-2 py-5 rounded-md">
                    <p className="text-base font-semibold pb-3">4.5</p>
                    <div className="flex gap-1 pb-3" style={{color: "yellow"}}>
                      <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStarHalfAlt />
                    </div>
                    <p className="text-xs font-semibold">User Ratings</p>
                  </div>
                  <div className="bg-black text-white p-5 rounded-md">
                    <p className="text-base font-semibold pb-2">10 Lakh +</p>
                    <div className="flex justify-center gap-2 pb-2 text-2xl" style={{color: "yellow"}}>
                      <MdGroups />
                    </div>
                    <p className="text-xs font-semibold">Total Users</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <p className="text-black text-xl font-semibold">Engaging in trading activities involves the possibility of incurring losses that surpass your deposited funds, and it is recommended solely for experienced clients who possess the necessary financial capacity to tolerate such risks. The information presented on this platform should not be considered as investment advice or a solicitation to trade any financial instrument.</p>
          </div>
        </div>
        <SignUpModal isOpen={isModalOpen} onClose={closeModal} />
      </section>
    </>
  );
};

export default LoginPage;
