import React, { useEffect, useState } from "react";
import { GrClose } from "react-icons/gr";
import { toast } from "react-toastify";
import { useUserContext } from "../../Context/userContext";

const SubscriptionModal = ({ isOpen, onClose }) => {


  const { user } = useUserContext();

  const [formData, setFormData] = useState({});
  const [currentData, setCurrentData] = useState({});
  const base_url = "https://mychart-backend.vercel.app/api/v1";
  // const base_url = "http://localhost:3000/api/v1";



  useEffect(() => {
    const handleGetPayentInfo = async () => {
      try {
        const update_url = `${base_url}/admin/paymentinfo`;
        const response = await fetch(update_url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            
          },
          credentials: "include",
        });
        const json = await response.json();
        setCurrentData(json.data);
      } catch (error) {
        toast.error(error.message);
      }
    };

    handleGetPayentInfo();
  }, []);

  if (!isOpen) return null;

  const handleOnChange = (input) => {
    setFormData({ ...formData, [input.target.name]: input.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { utrNo, Months } = formData;
    const errorMessageElement = document.querySelector(".error-message");
    errorMessageElement.textContent = "";

    if (!utrNo) {
      const errorMessage = "Please fill in both UTR No. fields.";
      errorMessageElement.textContent = errorMessage;
      return;
    }
    try {
      const transtionUrl = `${base_url}/user/payment`;
      const response = await fetch(transtionUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        credentials: "include",
        body: JSON.stringify({
          ...formData,
          Months: currentData?.month // Add currentData.month to the formData
        }),
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage || "Failed to submit data");
      }

      const json = await response.json();

      if (json.error) {
        toast.error(json.error);
      } else {
        toast.success(json.message);
        onClose();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  console.log(user)
  
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto py-2">
      <div className="flex items-center justify-center min-h-screen px-4">
        <div className="fixed inset-0 bg-black opacity-50"></div>
        <div className="flex justify-center items-center shadow-xl transform transition-all sm:max-w-lg sm:w-full">
          <div className="w-[320px] sm:w-[500px] bg-gray-900 p-10 rounded-lg border border-gray-700">
            <div className="flex justify-end">
              <button
                onClick={onClose}
                className="w-6 h-6 cursor-pointer text-gray-100 border rounded-full p-1 ">
                <GrClose />
              </button>
            </div>

            {user.status === "pending" ? (
              <>
                <div className="pendingrReq">
                  <h2 className="text-2xl font-semibold mb-4 text-gray-400 text-center">
                    Request is Pending
                  </h2>
                  <div class="loader"></div>
                </div>
              </>

            ) : (
              <>
                <h2 className="text-2xl font-semibold mb-4 text-gray-400 text-center">
                  SUBSCRIPTION
                </h2>

                <div className="flex flex-col items-center justify-center border rounded-lg gap-2 p-4">
                  <div className="flex flex-col gap-y-4">
                    <img
                      src={currentData?.qrcodeUrl}
                      className="w-[200px] h-[200px]"
                      alt=""
                    />
                  </div>

                  <div className="py-2">
                    <p className="text-2xl font-bold">
                      {currentData?.month} Months ( ₹ {currentData?.price}/-){" "}
                    </p>
                  </div>
                  <div className="flex flex-col sm:flex-row gap-4">
                    <div className="flex flex-col w-full sm:w-3/4">
                    <lable>Enter UTR</lable>
                    <input
                      onChange={handleOnChange}
                      name="utrNo"
                      type="text"
                      required
                      id="utrNo"
                      className="bg-transparent input text-gray-400 placeholder-gray-700 border-gray-600"
                      placeholder="ENTER YOUR  UTR NO"
                    />
                    </div>
                    <div className="flex flex-col w-full sm:w-1/4">
                    <lable>Months</lable>
                    <input
                      // onChange={handleOnChange}
                      name="Months"
                      type="text"
                      required
                      id="Months"
                      className="bg-transparent input text-gray-400 placeholder-gray-700 border-gray-600 w-25"
                      placeholder="ENTER NUMBER OF MONTHS"
                      defaultValue={currentData?.month} // Set default value
                      disabled // Disable the input field
                    />
                    </div>
                    <button
                      onClick={handleSubmit}
                      className="bg-green-600 hover:bg-green-700 hover:transition hover:delay-100 p-2 md:mt-6 rounded">
                      Send
                    </button>
                  </div>
                  <div>
                    <p className="error-message text-red-500"></p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionModal;
