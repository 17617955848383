// import React, { useEffect, useState } from "react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
// import OnlineUsers from "./OnlineUsers";

const AdminRequestUser = () => {
  const [allUser, setAllUser] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);

  // Fetch data from API
  // const base_url = "http://localhost:3000/api/v1";
  const base_url = "https://mychart-backend.vercel.app/api/v1";
  const handleFetch = async () => {

    const getUserUrl = `${base_url}/admin`;

    try {
      const response = await fetch(getUserUrl, {
        credentials: "include",
      });

      const json = await response.json();

      if (Array.isArray(json)) {
        setAllUser(json);
      } else {
        console.error("API response is not an array:", json);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    const handleFetch = async () => {
      const getUserUrl = `${base_url}/admin`;

      try {
        const response = await fetch(getUserUrl, {
          credentials: "include",
        });
        const json = await response.json();
        // console.log(json);

        if (Array.isArray(json)) {
          setAllUser(json);
        } else {
          console.error("API response is not an array:", json);
        }
      } catch (error) {
        console.log(error.message);
      }
    };

    handleFetch();
  }, []);

  const handleUserUpdate = async (payload) => {
  
    const userUpdateUrl = `${base_url}/admin/userstatus`;
    handleFetch();

    try {
      const response = await fetch(userUpdateUrl, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload), 
      });
      const json = await response.json();

      if (json.statuscode === 200) {
        toast.success(json.message);
      } else {
        toast(json.message);
      }
      handleFetch();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleAcceptRequest = (userId,month) => {
    console.log(month, userId);
    const startDate = new Date();
    const premiumEndDate = new Date(startDate);
    premiumEndDate.setMonth(premiumEndDate.getMonth() + month);
    handleUserUpdate({
      id: userId,
      status: true,
      premiumEndDate
    });
  };

  const handleDeclineRequest = (userId) => {
    // Implement logic for declining user request
    handleUserUpdate({ id: userId, status: false });
  };

  const handleFetchOnlineUsers = async () => {
    try {
      const getOnlineUsersUrl = `${base_url}/admin/getOnlineUsers`;

      const response = await fetch(getOnlineUsersUrl, {
        credentials: 'include',
      });
      const json = await response.json();

      if (json.success) {
        setOnlineUsers(json.onlineUsers);
      } else {
        console.error('Failed to fetch online users:', json);
      }
    } catch (error) {
      console.error('Error fetching online users:', error.message);
    }
  };

  return (
    <>
    <div className="flex flex-col overflow-x-auto">
      <div className="sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
          <div className="overflow-x-auto">
            <table className="  min-w-full text-left text-sm font-light">
              <thead className=" border-b font-medium dark:border-neutral-500">
                <tr className="bg-gray-700">
                  <th scope="col" className="px-6 py-4">
                    SL
                  </th>
                  <th scope="col" className="px-6 py-4">
                    NAME
                  </th>
                  <th scope="col" className="px-6 py-4">
                    EMAIL
                  </th>
                  <th scope="col" className="px-6 py-4">
                    PHONE
                  </th>
                  <th scope="col" className="px-6 py-4">
                    PREMIUM USER
                  </th>
                  <th scope="col" className="px-6 py-4">
                    STATUS
                  </th>
                  <th scope="col" className="px-6 py-4">
                    UTR NO
                  </th>
                  <th scope="col" className="px-6 py-4">
                    No. of Months
                  </th>
                  <th scope="col" className="px-6 py-4">
                    ACTION
                  </th>
                </tr>
              </thead>
              <tbody>
                {allUser.map(
                  (user, index) =>
                    user.status === "pending" && (
                      <tr className="border-b dark:border-neutral-500">
                        <td className="whitespace-nowrap px-6 py-4 font-medium">
                          {index + 1}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          {user?.name}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          {user?.email}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          {user?.phone}
                        </td>
                        {user?.isPremium ? (
                          <td className="whitespace-nowrap px-6 py-4">yes</td>
                        ) : (
                          <td className="whitespace-nowrap px-6 py-4 ">No</td>
                        )}
                        <td className="whitespace-nowrap px-6 py-4 ">
                          <span className="bg-gray-500 p-1 rounded text-white font-semibold border ">
                            {user.status}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          {user.utrNo}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          {user.subscriptionMonths}
                        </td>
                        {user?.status === "pending" ||
                        user?.status === "cancel" ? (
                          <td className="whitespace-nowrap px-6 py-4 flex flex-col sm:flex-row gap-4">
                            <button
                              className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded"
                              onClick={() => handleAcceptRequest(user._id, user.subscriptionMonths)}>
                              Accept
                            </button>

                            <button
                              className="bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded"
                              onClick={() => handleDeclineRequest(user._id)}>
                              Decline
                            </button>
                          </td>
                        ) : (
                          <td className="whitespace-nowrap px-6 py-4 ">
                            <button
                              className="w-full bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded"
                              onClick={() => handleDeclineRequest(user._id)}>
                              Cancle
                            </button>
                          </td>
                        )}
                      </tr>
                    )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div>
    </div>
    <div className="m-4 dark:bg-gray-800 p-3 rounded">
        <h3 className="text-xl mb-2 flex justify-between items-center text-white ">
          <span className="font-bold">
          Online Users
          </span>
          <button
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={handleFetchOnlineUsers}
          >
            Refresh
          </button>
        </h3>
        <ul className="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
          {onlineUsers.map(user => (
            <li key={user.id} className="m-3 text-white">
              <span className="font-bold ">{user.name}</span> - {user.email}
            </li>
          ))}
        </ul>
      </div>
    
    </>
  );
};

export default AdminRequestUser;
