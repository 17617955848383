import React, { useState } from "react";
import { FaUserAlt } from "react-icons/fa";
import { IoMdOptions } from "react-icons/io";
import { MdOutlineLogin } from "react-icons/md";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useUserContext } from "../../Context/userContext";
import Sidebar from "../Sidebar/Sidebar";
import SubscriptionModal from "../Subscription/SubscriptionModal";
import CounterDown from "../ui/CounterDown";
import FullDate from "../ui/Date";
import Time from "../ui/Time";
import Navmenu from "./Navmenu";
import logo from "../../icon.jpg";


export default function Header() {
  const [isLoading, setIsLoading] = useState(false);
  const { user, setUser } = useUserContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const openPopup = () => {
    setIsSidebarOpen(true);
  };

  const closePopup = () => {
    setIsSidebarOpen(false);
  };
  const openModal = () => {
    setIsLoading(true);
    setIsModalOpen(true);
    setTimeout(() => setIsLoading(false), 1000);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSignOut = async () => {
    const base_url = "https://mychart-backend.vercel.app/api/v1";
    const logout_url = `${base_url}/auth/sign-out`;
    // const logout_url = "http://localhost:3000/api/v1/auth/sign-out";


  try {
    const response = await fetch(logout_url, {
      method: 'POST', // Assuming your sign-out endpoint uses POST
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: "include",
      body: JSON.stringify({ email: user.email }), // Sending user email in the request body
    });

    const json = await response.json();

    if (json.success) {
      setUser({}); // Clear user state upon successful sign-out
      toast.success(json.message);
    } else {
      toast.error(json.message);
    }
  } catch (error) {
    console.error("Error signing out:", error);
    toast.error("Failed to sign out. Please try again.");
  }
};

  // const handleSignOut = async () => {
  //   // const base_url = "https://mychart-backend.vercel.app/api/v1";
  //   // const logout_url = `${base_url}/auth/sign-out`;
  //   const logout_url = "http://localhost:3000/api/v1/auth/sign-out";
  //   try {
  //     const response = await fetch(logout_url, {
  //       credentials: "include",
  //     });
  //     const json = await response.json();
  //     console.log("user==========",user.email)
      

  //     if (json.sucess) {
  //       setUser({});
  //       toast.success(json.message);

  //     }
  //   } catch (error) {
  //     toast.error(error.message);
  //   }
  // };

  return (
    <>
    {isLoading && (
        <div className="overlay">
          <div className="loader"></div>
        </div>
      )}
    <div>
      <div className="text-gray-300 flex flex-col sm:flex-row gap-2 justify-between sm:items-center w-[90%] mx-auto py-2">
        {/* first section */}
        <Link to="/"> 
        <div className="flex items-center mb-4">
          <img src={logo} alt="Logo" className="h-16 w-auto rounded-full mr-2 "/>
        </div>
        </Link>
        <div className="l flex flex-col sm:flex-row items-start  gap-3 sm:items-center">
          <div className="w-full sm:w-auto flex  gap-2 justify-between sm:flex-auto">
            <div>
              <div>
                <div>
                  <FullDate />
                  <Time />
                </div>
              </div>
            </div>
            <div className="flex  items-center justify-center p-2 border rounded-md  border-lime-200">
              <CounterDown />
            </div>
          </div>
          <div className=" border   flex items-center justify-center rounded-full p-2">
            <FaUserAlt title={user?.email} />
            <p className="pl-2"> {user.email}</p>
          </div>
        </div>

        {/* right section */}
        <div className="flex flex-col sm:flex-row items-start sm:items-center gap-x-4">
          {user.role === "admin" && (
            <div className=" flex gap-4">
              <Link to="/admin" className="bg-gray-700 p-2 rounded">
                <button>Admin dashborad</button>
              </Link>
            </div>
          )}
          {!user?.isPremium ? (
            <div className=" flex gap-4">
              <button
                onClick={openModal}
                className="p-2 rounded hover:opacity-50 bg-gray-600">
                Premium chart
              </button>

              <div
                className="w-28 font-bold cursor-pointer  flex gap-2 justify-center  items-center border border-gray-600 rounded hover:bg-gray-600 p-2"
                title="sign out"
                onClick={handleSignOut}>
                <MdOutlineLogin /> <span>sign-out</span>
              </div>
            </div>
          ) : (
            <div title="settings" className=" flex gap-4">
              <button
                className="w-12 h-12 flex items-center justify-center rounded-full border border-gray-600  hover:bg-gray-600 p-2"
                onClick={openPopup}>
                <IoMdOptions />
              </button>
              <div
                className="w-28 font-bold cursor-pointer  flex gap-2 justify-center  items-center border border-gray-600 rounded hover:bg-gray-600 p-2"
                title="sign out"
                onClick={handleSignOut}>
                <MdOutlineLogin /> <span>sign-out</span>
              </div>
            </div>
          )}

          {/* pop modal */}
        </div>
        <SubscriptionModal isOpen={isModalOpen} onClose={closeModal} />
        <Sidebar isOpen={isSidebarOpen} onClose={closePopup} />
      </div>

      {/* second section */}
      <Navmenu></Navmenu>
    </div>
    </>
  );
}
