import React from 'react'
import { Link } from 'react-router-dom'
import notFoundImg from "../../assets/404 Error with a cute animal-amico.png"
export default function NotFoundPage() {
  return (
    <div className="w-full h-screen">
    <div className="flex flex-col justify-center items-center pb-4">
      <img className="w-auto h-[500px]" src={notFoundImg} alt="" />

      <Link to="/">
        <button className="text-white  rounded-md hover:opacity-80  bg-blue-600 p-4">
          Go to Homepage{" "}
        </button>
      </Link>
    </div>
  </div>
  )
}
