import { createBrowserRouter } from "react-router-dom";

import PrivateRoute from "../components/PrivateRoute/PrivateRoute";
import Home from "../Pages/Home";
import EURGBP from "../Pages/SymbolPage/EURGBP";
import EURUSD from "../Pages/SymbolPage/EURUSD";
import GBPUSD from "../Pages/SymbolPage/GBPUSD";
import USDCAD from "../Pages/SymbolPage/USDCAD";
// eslint-disable-next-line
import AdminPrivateRoute from "../components/PrivateRoute/AdminPrivateRoute";
import AdminUpdatePayment from "../Pages/Admin/AdminUpdatePayment";
import AdminResetPassword from "../Pages/Admin/AdminResetPassword";
import AdminUserTable from "../Pages/Admin/AdminUserTable";
import AUDUSD from "../Pages/SymbolPage/AUDUSD";
import CADJPY from "../Pages/SymbolPage/CADJPY";
import EURJPY from "../Pages/SymbolPage/EURJPY";
import GBPJPY from "../Pages/SymbolPage/GBPJPY";
import USDJPY from "../Pages/SymbolPage/USDJPY";
import AdminRequestUser from "../Pages/Admin/AdminRequestUser";
import NotFoundPage from "../Pages/ErrorPage/NotFoundPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <PrivateRoute />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/eur-usd",
        element: <EURUSD />,
      },
      {
        path: "/gbp-usd",
        element: <GBPUSD />,
      },
      {
        path: "/eur-gbp",
        element: <EURGBP />,
      },
      {
        path: "/usd-cad",
        element: <USDCAD />,
      },
      {
        path: "/usd-jpy",
        element: <USDJPY />,
      },

      {
        path: "/cad-jpy",
        element: <CADJPY />,
      },

      {
        path: "/aud-usd",
        element: <AUDUSD />,
      },
      {
        path: "/gbp-jpy",
        element: <GBPJPY />,
      },
      {
        path: "/eur-jpy",
        element: <EURJPY />,
      },
    ],
  },
  {
    path: "/admin",
    element: <AdminPrivateRoute />,
    children:[
      {
        path: "/admin",
        element:  <AdminUserTable/>
      },

      {
        path: "/admin/user-req",
        element:  <AdminRequestUser/>
      },
      {
        path: "/admin/update-payment",
        element:  <AdminUpdatePayment/>
      },
      {
        path: "/admin/reset-password",
        element:  <AdminResetPassword/>
      }
    ]
  },
  {
    path: "/*",
    element: <NotFoundPage />,
    
  },
]);

export default router;
