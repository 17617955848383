import React from "react";
import { useUserContext } from "../../Context/userContext";

import AdminLayout from "../layout/Admin/AdminLayout";
import Forbidden from "../../Pages/ErrorPage/Forbidden";

export default function AdminPrivateRoute() {
  const { user } = useUserContext();
  return <>{user.role === "admin" ? <AdminLayout /> : <Forbidden />}</>;
}
