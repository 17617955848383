import React, { useEffect, useState } from 'react';


const CounterDown = () => {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(prevSeconds => {
        // Get the current time and calculate the remaining seconds
        const currentDate = new Date();
        const currentSecond = currentDate.getSeconds();
        const remainingSeconds = 59 - currentSecond;
        
        // If remaining seconds become negative, reset to 59
        return remainingSeconds >= 0 ? remainingSeconds : 59;
      });
    }, 1000);

    // Cleanup function to clear the interval
    return () => clearInterval(interval);
  }, []);

  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  return (
    <div className="">
      <div className="text-xs sm:text-xl font-bold">
        00:{formattedSeconds}
      </div>
    </div>
  );
};

export default CounterDown;

