  // TradingViewWidget.jsx
  import React, { memo, useEffect, useRef } from "react";
  import { useChartStateContext } from "../../Context/chartState";
  import '../Chart/tradingview.css'
  // import MATW from "../../indicators/MarketDeltaVolume_RealtimeBars.pine"

  function TradingViewWidget({ symbol }) {
    const { chartIndicator } = useChartStateContext();
    const container = useRef();
    if (!symbol) {
      symbol = "EURUSD";
    }
    

    useEffect(() => {

    
      // Create a new TradingView widget
      const script = document.createElement("script");
      script.src =
        "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
      script.type = "text/javascript";
      script.async = true;
      // Get user's timezone
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const studies = [];
      // let style = [];
      // if (chartIndicator.error_level === true) {
      //   studies.push("PUB;1055");
      //   // style.push("1");
      // } else {
      //   // If condition is false, remove from the array
      //   let index = studies.indexOf("PUB;1055");
      //   if (index !== -1) {
      //     studies.splice(index, 1);
      //   }
      // }

        if (chartIndicator.blockPressure === true) {
          studies.push("PUB;9533853ded4e404195105a1baf79d45e");
          //ZigZag Chart with Supertrend
        } else {
          // If condition is false, remove from the array
          let index = studies.indexOf("PUB;9533853ded4e404195105a1baf79d45e");
          if (index !== -1) {
            studies.splice(index, 1);
          }
        }

      if (chartIndicator.volumeDelta === true) {
        studies.push("PUB;40e0ea9f1e724b1c9f286ed927cc7e5c");
        //Institutional OrderBlock Pressure
        // style.push("5");
      } else {
        // If condition is false, remove from the array
        let index = studies.indexOf("PUB;40e0ea9f1e724b1c9f286ed927cc7e5c");
        if (index !== -1) {
          studies.splice(index, 1);
        }
      }
      if (chartIndicator.zigZag === true) {
        studies.push("PUB;1b9fe120579d43f2b5e2cb64bab3c87b");
        //Market Delta Volume for Realtime Bars
      
      } else {
        // If condition is false, remove from the array
        let index = studies.indexOf("PUB;1b9fe120579d43f2b5e2cb64bab3c87b");
        if (index !== -1) {
          studies.splice(index, 1);
        }
      }

    
      script.innerHTML = `
        {
          "autosize": true,
          "symbol": "${symbol}",
          "interval": "1",

          "timezone": "${userTimezone}",
          "theme": "dark",
          "style": "1",
          "locale": "en",
          "enable_publishing": true,
          "withdateranges": false,
          "hide_side_toolbar": true,
          "backgroundColor": "rgb(16, 1, 25)",
          "gridColor": "rgba(0, 0, 0, 0.06)",
          "hide_top_toolbar": true,
          "allow_symbol_change": true,
          "details": false,
          "hotlist": false,
          "calendar": true,
          "widgets": false,
          "hide_legend": true,
          "hide_volume": true,
          
          
          
          "studies": ${JSON.stringify(studies)}, 
          "support_host": "https://www.tradingview.com"
          
        }`;
      // Clear previous child nodes
      while (container?.current?.firstChild) {
      
        container.current.removeChild(container.current.firstChild);
      }
      container.current.appendChild(script);
    
    }, [symbol, chartIndicator]);

    return (
      <div
        className="tradingview-widget-container"
        ref={container}
        style={{ height: "100%", width: "100%" }}>
        <div
          className="tradingview-widget-container__widget"
          style={{ height: "calc(100% - 32px)", width: "100%" }}></div>
      </div>
    );
  }

  export default memo(TradingViewWidget);
