import React, { createContext, useContext, useEffect, useState } from "react";

// Create a context object
const UserContext = createContext();

// Create a provider component
export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  // const base_url = "http://localhost:3000/api/v1";

  useEffect(() => {
    const handleSubmit = async () => {

      
      const base_url = "https://mychart-backend.vercel.app/api/v1";
      const getUserUrl = `${base_url}/user`;

      try {
        const response = await fetch(getUserUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Session-Token": localStorage.getItem("sessionToken"),
          },
          credentials: "include",
        });
        // alert(localStorage.getItem("sessionToken"))
        const json = await response.json();

        if (json.error) {
        } else {
          setUser(json);
        }
      } catch (error) {
        console.log(error.message);
      }
  }
  
    handleSubmit();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use the context easily
export const useUserContext = () => {
  return useContext(UserContext);
};
