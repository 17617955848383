import React from "react";
import { GrClose } from "react-icons/gr";
import { useChartStateContext } from "../../Context/chartState";

const Sidebar = ({ isOpen, onClose }) => {
  const { chartIndicator, setChartIndicator } = useChartStateContext();

  const toggleSwitch = (key) => {
    setChartIndicator((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };
  const handleClear = () => {
    setChartIndicator({
      error_level: false,
      blockPressure: false,
      volumeDelta: false,
      zigZag: false,
    });
  };

  // console.log(chartIndicator);
  return (
    <div
      className={`fixed top-0 right-0 md:w-[20%] w-full  h-full flex items-center justify-center bg-[#13001a]  ${
        isOpen ? "" : "hidden"
      }`}>
      <div className=" p-8 rounded-lg shadow-lg flex flex-col items-end justify-end gap-y-2">
        <div className="flex justify-end  py-4">
          <GrClose
            onClick={onClose}
            className="w-6 h-6 cursor-pointer  text-gray-100 bg-red-400 border rounded-full p-1 hover:bg-red-500  "
          />
        </div>
{/* 
        <div className=" flex items-center justify-between mb-4 py-2 text-gray-400 border-b border-gray-700 w-full">
          <p id="switch-1" className="mr-4 text-xl">
         Chart 1:
          </p>
          <input
            htmlFor=""
            className=" h-6 w-6"
            type="checkbox"
            checked={chartIndicator.error_level}
            onChange={() => toggleSwitch("error_level")}
          />
        </div> */}
        <div className="flex justify-between items-center mb-4  text-gray-400 border-b border-gray-700 w-full ">
          <p className="mr-4 text-xl">Chart 1:</p>
          <input
            className=" h-6 w-6"
            type="checkbox"
            checked={chartIndicator.blockPressure}
            onChange={() => toggleSwitch("blockPressure")}
          />
        </div>
        <div className="flex justify-between items-center mb-4  text-gray-400 border-b border-gray-700 w-full">
          <p className="mr-4 text-xl">Chart 2:</p>
          <input
            className=" h-6 w-6"
            type="checkbox"
            checked={chartIndicator.volumeDelta}
            onChange={() => toggleSwitch("volumeDelta")}
          />
        </div>
        <div className="flex justify-between items-center mb-4  text-gray-400 border-b border-gray-700 w-full">
          <p className="mr-4 text-xl">Chart 3:</p>
          <input
            className=" h-6 w-6"
            type="checkbox"
            checked={chartIndicator.zigZag}
            onChange={() => toggleSwitch("zigZag")}
          />
        </div>
        

        <button
          className="w-full px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:bg-red-600"
          onClick={handleClear}>
          Clear
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
