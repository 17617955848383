import React from "react";

export default function Footer() {
  return (
    <div className="flex justify-center items-center py-8">
      <p className="text-center">
        © 2016-2024 Gochart Pvt. Ltd. All Rights Reserved.
      </p>
    </div>
  );
}
