import { RouterProvider } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ChartContextProvider } from "./Context/chartState";
import { UserContextProvider } from "./Context/userContext";
import router from "./routes/index";

function App() {
  return (
    <>
      <UserContextProvider>
        <ChartContextProvider>
          <RouterProvider router={router}></RouterProvider>
        </ChartContextProvider>
      </UserContextProvider>
      <ToastContainer position="bottom-right" pauseOnHover={false} theme="dark" newestOnTop />
    </>
  );
}

export default App;
